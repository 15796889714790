<template>
  <div class="preview-product-tiles">
    <div
      v-for="(item, index) of filteredItems"
      :key="item.id"
      class="product-tile"
    >
      <app-img
        :src="item.image"
        :to="RouterService.getRouteLocation('product', { slug: item.slug, locale })"
        :radius="true"
        :ratio="1"
        class="product-tile__image"
      />

      <div
        v-if="index + 1 === filteredItems.length && hiddenItemsCount"
        class="product-tile__more"
      >
        <span>{{ `+${hiddenItemsCount}` }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { BaseProduct } from 'src/models/product'
import RouterService from 'src/services/router.service'
import { computed } from 'vue'
import { useI18n } from 'src/composables/useI18n'

export interface Props {
  items: BaseProduct[]
  max?: number
}

const props = withDefaults(defineProps<Props>(), {
  max: 5
})

const { locale } = useI18n()

const filteredItems = computed(() => {
  return props.items.filter((item, index) => index < props.max)
})

const hiddenItemsCount = computed(() => {
  const diff = props.items.length - filteredItems.value.length
  return diff > 0 ? diff + 1 : 0
})
</script>

<style lang="scss" scoped>
.preview-product-tiles {
  --columns: v-bind(max);
  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  gap: var(--grid-gutter);
}
.product-tile {
  position: relative;
  flex: 1 1 auto;
  &__image {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    min-width: 40px;
  }
  &__more {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    font-size: 20px;
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      background-color: var(--theme-color-bg);
      transition: background-color var(--trs-1);
      opacity: 0.85;
    }
    span {
      position: relative;
      z-index: 2;
    }
  }
}
</style>
