<template>
  <app-section
    v-intersection-observer="([{ isIntersecting }]: IntersectionObserverEntry[]) => onIntersecting(isIntersecting)"
    class="product-groups"
  >
    <app-container>
      <div class="product-groups__head">
        <base-tabs
          v-model="activeTab"
          :tabs="tabs"
        />
      </div>

      <div v-if="activeTab" class="product-groups__content">
        <transition name="slide">
          <product-grid
            :key="activeTab"
            :list="productList"
            :skeleton="skeleton"
            :full-width="true"
          >
            <template #default="{ product }">
              <product-card :product="product" :no-detail="true" />
            </template>
          </product-grid>
        </transition>
      </div>
    </app-container>
  </app-section>
</template>

<script lang="ts" setup>
import BaseTabs from 'src/components/base/BaseTabs.vue'
import ProductCard from 'src/components/product/ProductCard.vue'
import ProductGrid from 'src/components/catalog/ProductGrid.vue'
import { useProductList } from 'src/composables/catalog/productList'
import { useProductsByIdQuery, useProductGroupsQuery } from 'src/composables/catalog/queries'
import type { TabList, TabItem } from 'src/types/tabs'
import { computed, ref, watchEffect } from 'vue'
import { vIntersectionObserver } from '@vueuse/components'
import { useModelList } from 'src/composables/modelList'
import { ProductGroups } from 'src/models/catalog/productGroups'

const isVisible = ref(false)

const productGroupsQuery = useProductGroupsQuery({
  enabled: isVisible
})

const productGroupModelList = useModelList(ProductGroups, productGroupsQuery.data)

const tabs = computed<TabList>(() => {
  return productGroupModelList.value.items.map(item => {
    return {
      id: String(item.id),
      label: item.name ?? ''
    }
  })
})

const activeTab = ref<TabItem['id'] | undefined>(undefined)

const productIdList = computed(() => {
  return productGroupModelList.value.items.find(item => String(item.id) === activeTab.value)?.productIdList ?? []
})

const skeleton = computed(() => productIdList.value.length || true)

const productListQuery = useProductsByIdQuery(productIdList, {
  enabled: isVisible,
  keepPreviousData: false
})

const productList = useProductList(productListQuery.data)

watchEffect(() => {
  const firstTab = productGroupModelList.value.items.find(item => !!item)
  if (firstTab && !activeTab.value) activeTab.value = String(firstTab.id)
})

const onIntersecting = (isIntersecting: boolean) => {
  isVisible.value = isIntersecting
}
</script>

<style lang="scss" scoped>
.product-groups {
  &__head {
    display: flex;
    justify-content: center;
    margin-bottom: var(--md);
  }
  &__content {
    position: relative;
  }
}
</style>
