<template>
  <app-section v-if="data && data.notEmpty">
    <app-container>
      <tile-banner :banner="data" />
    </app-container>
  </app-section>
</template>

<script lang="ts" setup>
import TileBanner from 'src/components/banner/TileBanner.vue'
import { useApiBannersGetMainTileBanner } from 'src/composables'

const { data } = useApiBannersGetMainTileBanner()
</script>

<style lang="scss" scoped>

</style>
