<template>
  <app-section v-if="model?.notEmpty && show">
    <app-container>
      <app-content
        :rounded="true"
        :loading="isPending"
        class="cart-preview"
      >
        <div class="cart-preview__head">
          <div class="cart-preview__desctiption">
            <div class="text-subtitle2 text-weight-medium">{{ t('translate.cartProductsCount', { n: model.totalItems }) }}</div>
            <div>
              <span class="text-muted">{{ `${t('translate.forTheAmount')} ` }}</span>
              <span class="cart-preview__price text-weight-bold">{{ formattedTotalPrice }}</span>
            </div>
          </div>

          <app-button
            v-if="$q.screen.lt.lg"
            class="cart-preview__head-close"
            outline
            square
            :icon="iconXmark"
            @click="onClose"
          />
        </div>

        <div v-if="$q.screen.gt.xs" class="cart-preview__products-wrapper">
          <product-preview-tiles
            class="cart-preview__products"
            :items="items"
            :max="maxItems"
          />
        </div>

        <div class="cart-preview__controls">
          <app-button
            v-if="$q.screen.gt.xs"
            outline
            :label="t('actions.showCart')"
            class="cart-preview__cart-button"
            @click="nav.cart.value.onClick"
          />

          <app-button
            color="primary"
            :label="t('actions.checkout')"
            class="cart-preview__checkout-button"
            @click="stateStore.showCheckout()"
          />

          <app-button
            v-if="$q.screen.gt.md"
            outline
            square
            :icon="iconXmark"
            @click="onClose"
          />
        </div>
      </app-content>
    </app-container>
  </app-section>
</template>

<script lang="ts" setup>
import iconXmark from 'assets/icons/light/xmark.svg?raw'
import ProductPreviewTiles from 'src/components/product/ProductPreviewTiles.vue'
import { useI18n } from 'src/composables/useI18n'
import { useCartComputed } from 'src/composables/cart'
import { computed } from 'vue'
import { useNav } from 'src/composables/nav'
import { useStateStore } from 'src/stores/state'
import { useBreakpoint } from 'src/composables/breakpoint'
import { useCart } from 'src/composables/cart'
import { ref } from 'vue'

const { model, isPending } = useCart()
const { formattedTotalPrice } = useCartComputed(() => model.value)

const stateStore = useStateStore()

const { t } = useI18n()

const nav = useNav()

const items = computed(() => model.value?.list.items.map(item => item.baseProduct) ?? [])

const maxItems = useBreakpoint({ xs: 4, sm: 5, md: 4, lg: 4, xl: 7 })

const show = ref(true)

const onClose = () => {
  show.value = false
}
</script>

<style lang="scss" scoped>
.cart-preview {
  padding: var(--md) var(--lg);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--md) 60px;
  &__head {
    flex: 0 0 auto;
  }
  &__controls {
    flex: 0 0 auto;
    display: flex;
    flex-wrap: wrap;
    gap: var(--sm) var(--md);
  }
  &__products-wrapper {
    flex: 1 1 auto;
  }
  &__products {
    max-width: 440px;
  }

  @media (max-width: $breakpoint-lg-max) {
    gap: var(--md) 40px;
  }

  @media (max-width: $breakpoint-md-max) {
    gap: var(--md);

    &__head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__products {
      max-width: 300px;
    }
  }

  @media (max-width: $breakpoint-sm-max) {
    padding: var(--md);
    &__controls {
      width: 100%;
    }
    &__cart-button {
      flex: 1 1 auto;
    }
    &__checkout-button {
      flex: 2 1 auto;
    }
    &__products-wrapper {
      max-width: 100%;
    }
    &__products {
      max-width: 100%;
    }
  }

  @media (max-width: $breakpoint-xs-max) {
    padding: var(--md);
  }
}
</style>
