import { Model } from 'src/models/model'
import type { ID, Nullable } from 'src/types'

export interface BaseBannerItem {
  id: ID
  url?: string
  position?: Nullable<number>
}

export abstract class BaseBannerItemModel<M extends BaseBannerItem> extends Model<M> {
  constructor(data: M) {
    super(data)
  }

  get id() {
    return this.data.id
  }

  get url() {
    return this.data.url
  }

  get position() {
    return this.data.position ?? 0
  }
}

