import { Model } from 'src/models/model'
import { ModelList } from 'src/models/modelList'
import type { ProductIdList } from 'src/models/product'
import type { ID, Nullable } from 'src/types'

export interface ProductGroupData {
  id: ID
  name: Nullable<string>
  position: Nullable<number>
  products: Nullable<ProductIdList>
}

export class ProductGroup extends Model<ProductGroupData> {
  constructor(data: ProductGroupData) {
    super(data)
  }

  get id() {
    return this.data.id
  }

  get name() {
    return this.data.name ?? undefined
  }

  get position() {
    return this.data.position ?? 0
  }

  get productIdList(): ProductIdList {
    return this.data.products ?? []
  }
}

export class ProductGroups extends ModelList<typeof ProductGroup> {
  constructor(items?: ProductGroupData[]) {
    super(ProductGroup, items)
  }

  get items() {
    return [...super.items].sort((a, b) => a.position - b.position)
  }
}
