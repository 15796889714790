import type { Nullable } from 'src/types'
import { type BaseBannerItem, BaseBannerItemModel } from './base-banner.model'
import { ModelList } from 'src/models/modelList'
import { normalizeMediaUrl } from 'src/utils'

export interface TileBannerItem extends BaseBannerItem {
  lgUrl: Nullable<string>
}

export class TileBannerItemModel extends BaseBannerItemModel<TileBannerItem> {
  constructor(data: TileBannerItem) {
    super(data)
  }

  get imgUrl() {
    if (!this.data.lgUrl) return
    return normalizeMediaUrl(this.data.lgUrl)
  }
}

export class TileBannerItemModelList extends ModelList<typeof TileBannerItemModel> {
  constructor(items?: TileBannerItem[]) {
    super(TileBannerItemModel, items)
  }

  get items() {
    return [...super.items].sort((a, b) => a.position - b.position)
  }
}
