import { useQuery } from 'src/composables'
import { BigBannerItemModelList } from 'src/models/banner/big-banner.model'
import type { MaybeRefOrGetter } from 'vue'

export function useApiBannersGetMainBannerQuery(
  options?: Partial<{ enabled: MaybeRefOrGetter<boolean> }>
) {
  return useQuery({
    queryKey: ({ queryKeys, locale }) => queryKeys.banners.getMainBanner(locale).queryKey,
    queryFn: ({ api }) => api.banners.getMainBanner(),
    dataTransformer: (data) => new BigBannerItemModelList(data),
    enabled: options?.enabled
  })
}
