import { normalizeMediaUrl } from 'src/utils'
import type { Nullable } from 'src/types'
import { type BaseBannerItem, BaseBannerItemModel } from './base-banner.model'
import { ModelList } from 'src/models/modelList'

export interface BigBannerItem extends BaseBannerItem {
  lgUrl: Nullable<string>
  smUrl: Nullable<string>
}

export class BigBannerItemModel extends BaseBannerItemModel<BigBannerItem> {
  constructor(data: BigBannerItem) {
    super(data)
  }

  get smImageUrl() {
    if (!this.data.smUrl) return
    return normalizeMediaUrl(this.data.smUrl)
  }

  get lgImageUrl() {
    if (!this.data.lgUrl) return
    return normalizeMediaUrl(this.data.lgUrl)
  }
}


export class BigBannerItemModelList extends ModelList<typeof BigBannerItemModel> {
  constructor(items?: BigBannerItem[]) {
    super(BigBannerItemModel, items)
  }

  get items() {
    return [...super.items].sort((a, b) => a.position - b.position)
  }
}
