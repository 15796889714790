<template>
  <component
    :is="isRouterLink ? 'router-link' : 'a'"
    v-bind="linkProps"
    class="ui-link"
  >
    <slot />
  </component>
</template>

<script lang="ts" setup>
import { isString } from 'lodash'
import { isAbsoluteUrl } from 'src/utils'
import { computed } from 'vue'
import type { RouteLocationRaw } from 'vue-router'

const props = withDefaults(defineProps<{
  to?: RouteLocationRaw
  href?: string
  rel?: string
  target?: '_blank' | '_self' | '_parent' | '_top'
}>(), {
  rel: 'noopener',
  target: '_blank'
})

const isRouterLink = computed(() => {
  const { to } = props
  if (to && !(isString(to) && isAbsoluteUrl(to))) return true
  return false
})

const linkProps = computed(() => {
  const { to, href, target, rel } = props

  if (isRouterLink.value) return { to }

  return {
    href: to || href,
    target,
    rel
  }
})
</script>

<style lang="scss" scoped>

</style>
