import { ModelList } from 'src/models/modelList'
import { CategoryItem, type CategoryItemData } from 'src/models/catalog/category'

export interface TopCategoryItemData extends CategoryItemData {
  showAsTopSellers: number
}

export class TopCategoryItem extends CategoryItem<TopCategoryItemData> {
  constructor(data: TopCategoryItemData) {
    super(data)
  }

  get position() {
    return this.data.showAsTopSellers
  }
}

export class TopCategoryList extends ModelList<typeof TopCategoryItem> {
  constructor(items?: TopCategoryItemData[]) {
    super(TopCategoryItem, items)
  }

  get items() {
    return [...super.items].sort((a, b) => a.position - b.position)
  }
}

