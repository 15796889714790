<template>
  <router-link class="category-item" :to="categoryItemComputed.to.value">
    <app-content rounded class="category-item__content">
      <app-img
        class="category-item__image"
        fit="contain"
        ratio="1"
        :src="model.imageUrl"
        :radius="true"
      />

      <div v-if="model.name" class="category-item__text">
        {{ model.name }}
      </div>
    </app-content>
  </router-link>
</template>

<script setup lang="ts">
import { type CategoryItem } from 'src/models/catalog/category'
import { useCategoryItemComputed } from 'src/composables/catalog/category'

export interface Props {
  model: CategoryItem
}

const props = defineProps<Props>()

const categoryItemComputed = useCategoryItemComputed(() => props.model)
</script>

<style lang="scss" scoped>
.category-item {
  text-decoration: none;
  display: block;
  color: var(--theme-color);
  transition: color var(--trs-1), box-shadow var(--trs-1);
  border-radius: var(--global-border-radius);
  &__content {
    padding: var(--md);
    height: 100%;
  }
  &__image {
    width: 100px;
    margin: 0 auto;
  }
  &__text {
    text-align: center;
    margin-top: 10px;
  }

  body.desktop &:hover {
    color: var(--theme-color-primary);
    box-shadow: var(--shadow);
  }
}
</style>
