<template>
  <app-content v-if="banner.notEmpty" rounded class="big-banner">
    <slider-root
      :slides-per-view="1"
      :loop="true"
      :autoplay="true"
      class="big-banner__slider"
      @state="onStateUpdate"
    >
      <ui-link
        v-for="item in banner.items"
        :key="item.id"
        :to="item.url && normalizeUrl(item.url)"
        class="big-banner-slide"
      >
        <app-img
          :src="$q.screen.lt.md ? item.smImageUrl : item.lgImageUrl"
          fetchpriority="high"
          loading="eager"
          class="big-banner-slide__image"
        />
      </ui-link>
    </slider-root>

    <base-slider-counter
      v-if="slider"
      :active-index="slider.realIndex"
      :total-slides="slider.totalSlides"
      class="big-banner__counter"
    />

    <slider-controls
      v-if="slider"
      :slider="slider"
      :overlay="true"
      class="big-banner__controls"
    />
  </app-content>
</template>

<script lang="ts" setup>
import type { BigBannerItemModelList } from 'src/models/banner/big-banner.model'
import UiLink from 'src/components/ui/UiLink.vue'
import BaseSliderCounter from 'src/components/base/BaseSliderCounter.vue'
import SliderRoot, { type SliderRootState } from 'src/components/slider/SliderRoot.vue'
import SliderControls from 'src/components/slider/SliderControls.vue'
import { ref } from 'vue'
import { useNormalizeUrl } from 'src/composables/utils'

defineProps<{
  banner: BigBannerItemModelList
}>()

const normalizeUrl = useNormalizeUrl()

const slider = ref<SliderRootState | null>(null)

function onStateUpdate(state: SliderRootState) {
  slider.value = state
}
</script>

<style lang="scss" scoped>
.big-banner {
  position: relative;
  overflow: hidden;

  &__slider {
    --space-between: 0px !important;
    position: relative;
    z-index: 1;
  }
  &__counter {
    position: absolute;
    z-index: 2;
    left: var(--grid-px);
    bottom: var(--grid-px);
  }
  &__controls {
    position: absolute;
    z-index: 2;
    right: var(--grid-px);
    bottom: var(--grid-px);
  }
  @media (max-width: $breakpoint-xs-max) {
    border-radius: initial !important;
    margin: 0 calc(var(--grid-px) * -1);
  }
}

.big-banner-slide {
  --height: 25%;
  display: block;
  position: relative;
  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: var(--height);
  }
  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  @media (max-width: $breakpoint-sm-max) {
    --height: 100%;
  }
}
</style>
