<template>
  <q-no-ssr>
    <cart-preview-section class="cart-preview-section" />
  </q-no-ssr>

  <main-banner class="main-banner" />

  <app-section class="catalog-button xs">
    <app-container>
      <app-button
        :label="catalog.label"
        :icon="catalog.icon"
        :full-width="true"
        color="primary"
        @click="catalog.onClick?.()"
      />
    </app-container>
  </app-section>

  <top-categories />

  <q-no-ssr>
    <product-groups />
  </q-no-ssr>

  <q-no-ssr>
    <main-page-tile-banner />
  </q-no-ssr>

  <q-no-ssr>
    <viewed-products-section />
  </q-no-ssr>

  <q-no-ssr>
    <news-preview-section />
  </q-no-ssr>
</template>

<script setup lang="ts">
import ProductGroups from 'src/components/sections/ProductGroups.vue'
import MainBanner from 'src/components/sections/MainBanner.vue'
import MainPageTileBanner from 'src/components/sections/MainPageTileBanner.vue'
import ViewedProductsSection from 'src/components/sections/ViewedProductsSection.vue'
import TopCategories from 'src/components/sections/TopCategories.vue'
import NewsPreviewSection from 'src/components/sections/NewsPreviewSection.vue'
import CartPreviewSection from 'src/components/sections/CartPreviewSection.vue'
import { useNav } from 'src/composables/nav'
import { usePrefetchStore } from 'src/stores/prefetch'
import { queryKeys } from 'src/api'

const { catalog } = useNav()
</script>

<script lang="ts">
export default {
  async preFetch({ store }) {
    if (process.env.CLIENT) return

    const { api, i18n, queryClient } = usePrefetchStore(store)

    await queryClient.prefetchQuery({
      queryKey: queryKeys.catalog.getTopCategories(i18n.global.locale).queryKey,
      queryFn: () => api.catalog.getTopCategories()
    })
  }
}
</script>

<style lang="scss" scoped>
.cart-preview-section {
  @media (max-width: $breakpoint-xs-max) {
    --section-my: 0 !important;
    margin-top: calc(var(--layout-pt) * -1);
    padding: var(--lg) 0;
  }
}

.main-banner {
  @media (max-width: $breakpoint-xs-max) {
    --section-my: var(--lg) !important;
    &:first-child {
      position: relative;
      margin-top: calc(var(--layout-pt) * -1);
    }
  }
}

.catalog-button {
  --section-my: var(--lg) !important;
}
</style>
