<template>
  <app-section>
    <app-container>
      <big-banner
        v-if="data && data.notEmpty"
        :banner="data"
      />

      <big-banner-skeleton v-else />
    </app-container>
  </app-section>
</template>

<script lang="ts" setup>
import BigBannerSkeleton from 'src/components/banner/BigBannerSkeleton.vue'
import BigBanner from 'src/components/banner/BigBanner.vue'
import { useApiBannersGetMainBannerQuery } from 'src/composables'

const { data } = useApiBannersGetMainBannerQuery()
</script>

<style lang="scss" scoped>

</style>
