<template>
  <div v-if="banner.notEmpty" class="tile-banner">
    <slider-root
      :breakpoints="breakpoints"
      :pagination="true"
      class="tile-banner__slider"
      @state="onStateState"
    >
      <app-content
        v-for="item in banner.items"
        :key="item.id"
        :rounded="true"
        class="tile-banner-slide"
      >
        <app-img
          :src="item.imgUrl"
          :to="item.url ? normalizeUrl(item.url) : undefined"
          class="tile-banner-slide__image"
        />
      </app-content>
    </slider-root>
  </div>
</template>

<script lang="ts" setup>
import type { SwiperOptions } from 'swiper/types'
import type { TileBannerItemModelList } from 'src/models/banner/tile-banner.model'
import SliderRoot, { type SliderRootState } from 'src/components/slider/SliderRoot.vue'
import { BREAKPOINT } from 'src/config'
import { ref } from 'vue'
import { useNormalizeUrl } from 'src/composables/utils'

defineProps<{
  banner: TileBannerItemModelList
}>()

const normalizeUrl = useNormalizeUrl()

const breakpoints: SwiperOptions['breakpoints'] = {
  [BREAKPOINT.xs]: {
    slidesPerView: 2,
    slidesPerGroup: 2
  },
  [BREAKPOINT.sm]: {
    slidesPerView: 2,
    slidesPerGroup: 2
  },
  [BREAKPOINT.md]: {
    slidesPerView: 3,
    slidesPerGroup: 3
  },
  [BREAKPOINT.lg]: {
    slidesPerView: 4,
    slidesPerGroup: 4
  },
  [BREAKPOINT.xl]: {
    slidesPerView: 5,
    slidesPerGroup: 5
  }
}

const slider = ref<SliderRootState | null>(null)

const onStateState = (state: SliderRootState) => {
  slider.value = state
}
</script>

<style lang="scss" scoped>
.tile-banner {
  position: relative;
}

.tile-banner-slide {
  --height: 160.5%;
  display: block;
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: var(--height);
  }
  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
</style>
