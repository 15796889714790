<template>
  <app-section
    v-intersection-observer="([{ isIntersecting }]: IntersectionObserverEntry[]) => onIntersecting(isIntersecting)"
    class="top-categories"
  >
    <app-container>
      <slider-root
        v-if="topCategoryList.notEmpty"
        :breakpoints="breakpoints"
        :pagination="true"
        @state="onStateUpdate"
      >
        <category-item
          v-for="item in topCategoryList.items"
          :key="item.id"
          :model="item"
          class="full-height"
        />
      </slider-root>

      <div
        v-else
        class="top-categories__skeletons"
      >
        <div class="top-categories__skeletons-inner">
          <category-item-skeleton
            v-for="index in 7"
            :key="index"
          />
        </div>
      </div>
    </app-container>
  </app-section>
</template>

<script setup lang="ts">
import CategoryItem from 'src/components/catalog/category/CategoryItem.vue'
import CategoryItemSkeleton from 'src/components/catalog/category/CategoryItemSkeleton.vue'
import SliderRoot, { type SliderRootState } from 'src/components/slider/SliderRoot.vue'
import { useTopCategoriesQuery } from 'src/composables/catalog/queries'
import { BREAKPOINT } from 'src/config'
import { ref } from 'vue'
import { vIntersectionObserver } from '@vueuse/components'
import { useModelList } from 'src/composables/modelList'
import { TopCategoryList } from 'src/models/catalog/topCategories'

const breakpoints = {
  [BREAKPOINT.xs]: {
    slidesPerView: 2
  },
  [BREAKPOINT.sm]: {
    slidesPerView: 3
  },
  [BREAKPOINT.md]: {
    slidesPerView: 4
  },
  [BREAKPOINT.lg]: {
    slidesPerView: 6
  },
  [BREAKPOINT.xl]: {
    slidesPerView: 7
  }
}

const slider = ref<SliderRootState | null>(null)

const isVisible = ref(false)

const { data } = useTopCategoriesQuery({ enabled: isVisible })

const topCategoryList = useModelList(TopCategoryList, data)

const onIntersecting = (isIntersecting: boolean) => {
  isVisible.value = isIntersecting
}

function onStateUpdate(state: SliderRootState) {
  slider.value = state
}
</script>

<style lang="scss" scoped>
.top-categories {
  &__skeletons {
    overflow: hidden;
  }
  &__skeletons-inner {
    display: grid;
    gap: 0 var(--grid-gutter);
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-auto-rows: 0px;
    @media (min-width: $breakpoint-sm-min) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: $breakpoint-md-min) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (min-width: $breakpoint-lg-min) {
      grid-template-columns: repeat(6, 1fr);
    }
    @media (min-width: $breakpoint-xl-min) {
      grid-template-columns: repeat(7, 1fr);
    }
  }
}
</style>
