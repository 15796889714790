import type { Screen } from 'quasar'
import { useQuasar } from 'quasar'
import { computed } from 'vue'

export type UseBreakpointData<T> = Record<Screen['name'], T>

export function useBreakpoint<T>(data: UseBreakpointData<T>) {
  const $q = useQuasar()
  return computed(() => data[$q.screen.name])
}
