<template>
  <app-section
    v-intersection-observer="([{ isIntersecting }]: IntersectionObserverEntry[]) => onIntersecting(isIntersecting)"
    class="blog-preview-section"
  >
    <app-container v-if="blogResults.list.value.notEmpty">
      <slider-section
        :title="t('translate.news')"
        :breakpoints="breakpoints"
        :slides-per-view="'auto'"
      >
        <blog-post-preview-small
          v-for="post in blogResults.list.value.showOnTopItems"
          :key="post.id"
          :post="post"
          class="blog-preview-section__post"
        />
      </slider-section>
    </app-container>
  </app-section>
</template>

<script lang="ts" setup>
import BlogPostPreviewSmall from 'src/components/blog/BlogPostPreviewSmall.vue'
import SliderSection from 'src/components/slider/SliderSection.vue'
import { useApiBlogGetBlogQuery, useBlogResults, useI18n } from 'src/composables'
import { BREAKPOINT } from 'src/config'
import { ref } from 'vue'
import { vIntersectionObserver } from '@vueuse/components'

const breakpoints = {
  [BREAKPOINT.sm]: {
    slidesPerView: 2
  },
  [BREAKPOINT.md]: {
    slidesPerView: 3
  },
  [BREAKPOINT.lg]: {
    slidesPerView: 4
  },
  [BREAKPOINT.xl]: {
    slidesPerView: 5
  }
}

const { t } = useI18n()

const isVisible = ref(false)

const blogQuery = useApiBlogGetBlogQuery(undefined, { enabled: isVisible })

const blogResults = useBlogResults(() => blogQuery.data.value?.results)

const onIntersecting = (isIntersecting: boolean) => {
  isVisible.value = isIntersecting
}
</script>

<style lang="scss" scoped>
.blog-preview-section {
  &__post {
    height: 100%;
  }
  @media (max-width: $breakpoint-xs-max) {
    &__post {
      width: var(--min-content-width);
    }
  }
}
</style>
